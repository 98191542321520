<template>
    <div class="module-single">
        <div class="module-main">
            <div class="module-tit-group">
                <p class="tit">产品中心</p>
                <p class="tit-en">Product Center</p>
                <span class="line"></span>
            </div>
            <ul class="product-center-group">
                <li @click="showDownloadPop('云搬砖安卓应用端')">
                    <div class="product-single">
                        <img class="product-img" src="../../assets/image/home/product1.png" alt="">
                        <div class="hover-info">
                            <img src="../../assets/image/home/product_hover1.png" alt="">
                        </div>
                    </div>
                    <p class="product-tit">云搬砖安卓应用端</p>
                    <p class="product-describe">请用手机扫码进行下载</p>
                </li>
                <li @click="showDownloadPop('云搬砖苹果应用端')">
                    <div class="product-single">
                        <img class="product-img" src="../../assets/image/home/product2.png" alt="">
                        <div class="hover-info">
                            <img src="../../assets/image/home/product_hover2.png" alt="">
                        </div>
                    </div>
                    <p class="product-tit">云搬砖苹果应用端</p>
                    <p class="product-describe">请用手机扫码进行下载</p>
                </li>
                <li @click="toStoreIndex">
                    <div class="product-single">
                        <img class="product-img" src="../../assets/image/home/product3.png" alt="">
                        <div class="hover-info">
                            <img src="../../assets/image/home/product_hover3.png" alt="">
                        </div>
                    </div>
                    <p class="product-tit">云搬砖商家中心</p>
                    <p class="product-describe">点击进行商家中心</p>
                </li>
                <li @click="showDownloadPop('微配云店')">
                    <div class="product-single">
                        <img class="product-img" src="../../assets/image/home/product4.png" alt="">
                        <div class="hover-info">
                            <img src="../../assets/image/home/product_hover4.png" alt="">
                        </div>
                    </div>
                    <p class="product-tit">微配云店</p>
                    <p class="product-describe">请先下载APP后使用</p>
                </li>
            </ul>
        </div>
        <el-dialog
                :visible.sync="downloadPopShow"
                class="download-prompt-pop"
                :lock-scroll="false"
                width="600px">
            <div class="download-prompt-main">
                <p class="tit-box">{{downloadText}}</p>
                <div class="img-box">
                    <img src="../../assets/image/main-page/code_img.png" alt="">
                </div>
                <div class="close-btn" @click="closeDownloadPop">
                    <img src="../../assets/image/main-page/close_icon.png" alt="">
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "product-module",
        data(){
            return {
                downloadPopShow: false,//下载提示弹窗显示状态
                downloadText: "",//下载提示文字
            }
        },
        methods: {
            //显示下载提示弹窗
            showDownloadPop(text){
                this.downloadText = text;
                this.downloadPopShow = true;
            },
            //关闭弹窗
            closeDownloadPop(){
                this.downloadPopShow = false;
            },
            //跳转至商家中心
            toStoreIndex(){
                window.open('http://www.chinachem.cn/#/login', '_blank');
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/css/base";
    @import "../../assets/css/main-module";
    .download-prompt-pop{
        .el-dialog{
            border-radius: 10px;
            overflow: hidden;
            background: none;
            .el-dialog__header{
                display: none;
            }
            .el-dialog__body{
                padding: 0;
                .download-prompt-main{
                    width: 600px;
                    background-color: #f5f5f5;
                    .tit-box{
                        font-size: 28px;
                        color: #fff;
                        line-height: 144px;
                        background-color: $blueColor;
                        text-align: center;
                    }
                    .img-box{
                        padding: 40px 57px;
                        width: 485px;
                        height: 486px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .close-btn{
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 18px;
                        right: 18px;
                        padding: 10px;
                        cursor: pointer;
                        &:hover{
                            opacity: .85;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .product-center-group{
        @include flex-between;
        margin-top: 64px;
        li{
            width: 256px;
            text-align: center;
            .product-single{
                width: 256px;
                height: 256px;
                border-radius: 50%;
                overflow: hidden;
                position: relative;
                z-index: 1;
                cursor: pointer;
                .product-img{
                    width: 100%;
                    height: 100%;
                }
                .hover-info{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 256px;
                    height: 256px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: rgba(9, 69, 169, .5);
                    @include flex-center;
                    transform: scale(0);
                    transform-origin: 50% 50%;
                    transition: all .5s ease-in;
                    img{
                        width: 110px;
                        height: 110px;
                    }
                }
                &:hover{
                    .hover-info{
                        transform: scale(1);
                        transform-origin: 50% 50%;
                        transition: all .5s ease-out;
                    }
                }
            }
            .product-tit{
                font-size: 18px;
                line-height: 30px;
                color: $mainColor;
                margin-top: 24px;
            }
            .product-describe{
                font-size: 14px;
                line-height: 30px;
                margin-top: 12px;
                color: #999;
            }
        }
    }
</style>
